import { Box, Container } from "@mui/material";
import React from "react";
import styles from "./hero.desktop.module.css";

function HeroDesktop({ className, style, sx, children }) {
  return (
    <Box
      className={[styles.heroSectionDesktop, className ?? ""].join(" ")}
      style={style}
      sx={sx}
    >
      {children && <Container>{children}</Container>}
    </Box>
  );
}

export default HeroDesktop;
