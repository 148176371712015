import React, { useState, useEffect, useRef } from "react";
import useIsMobile from "../../utils/device.type.hook";
import {
  Dialog,
  Stack,
  Box,
  Typography,
  IconButton,
  TextareaAutosize,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import Image from "next/image";
import StarRating from "./star.rating";
import MediaSlider from "./media.slider";
import UploadIcon from "../../public/images/icons/upload_gray.svg";
import { handlePostApiCall } from "../../utils/api.call";
import { toast } from "react-toastify";
import { getDateInSlashSeparatorFormat } from "../../utils/date";
import useScrollToComponent from "../../utils/use.scroll.to.component";
import { parseTripObject } from "../trip/trip";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import ALink from "../ui/link";
import { parseReviewObj } from "./review";

const REVIEW_FOR = {
  ITINERARY: "itinerary",
  HOST: "host",
};

const reviewSubmissionOptions = [
  {
    title: "Text-only Review",
    description: "Express your thoughts in 50 or more words.",
  },
  {
    title: "Video-only Review",
    description: "Express yourself with a video, in mov/mp4 format upto 50MB.",
  },
  {
    title: "Text with Image/Video Review",
    description: "Combine text with visuals for richer, detailed reviews.",
  },
];

/**
 * @param {Object} props
 * @param {boolean} [props.isOpen]
 * @param {Function} [props.handleClose]
 * @param {Object} [props.hostDetails]
 * @param {"trip" | "allReviews" | "dashboard"} [props.pageType]
 * @param {Function} [props.handleCreatedReviewFn]
 */
function ReviewDialog({
  isOpen = false,
  handleClose = () => {},
  hostDetails = {},
  pageType = "trip",
  handleCreatedReviewFn = () => {},
}) {
  const isMobile = useIsMobile();
  const profile = useSelector((state) => state.AuthReducer.profile);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [customerName, setCustomerName] = useState("");
  const inputRef = useRef(null);
  const [medias, setMedias] = useState([]);
  const [isMediaUploading, setIsMediaUploading] = useState(false);
  const [mediaIdsBeingRemoved, setMediasIdsBeingRemoved] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createdReviewDetails, setCreatedReviewDetails] = useState(null);

  const [selectedTrip, setSelectedTrip] = useState(null);
  const [trips, setTrips] = useState([]);
  const [tripsPage, setTripsPage] = useState(0);
  const [isFetchingTrips, setIsFetchingTrips] = useState(false);
  const loadMoreTripsRef = useRef(null);
  const isLoadMoreTripsVisible = useScrollToComponent(
    loadMoreTripsRef,
    [isMobile],
    { root: null, threshold: 0.1 }
  );
  const [hasExaustedTrips, setHasExaustedTrips] = useState(false);

  const reviewForEntityType = selectedTrip?.id
    ? REVIEW_FOR.ITINERARY
    : REVIEW_FOR.HOST;
  const entityId =
    reviewForEntityType === REVIEW_FOR.ITINERARY
      ? selectedTrip?.itineraryDetails?.id
      : hostDetails?.id;

  const starSize = createdReviewDetails ? 30 : 24;
  const mediasToUse = createdReviewDetails
    ? createdReviewDetails?.medias
    : medias;
  const isDisabledForHost =
    profile?.isHost &&
    profile?.host?.id === hostDetails?.id &&
    pageType !== "dashboard";

  useEffect(() => {
    if (!isDisabledForHost && isOpen && !trips.length) {
      handleSearchTrips();
    }
  }, [isOpen, trips]);

  useEffect(() => {
    if (isLoadMoreTripsVisible) {
      handleSearchTrips();
    }
  }, [isLoadMoreTripsVisible]);

  function handleDataReset() {
    setCustomerName("");
    setRating(0);
    setReview("");
    setMedias([]);
    setIsMediaUploading(false);
    setMediasIdsBeingRemoved([]);
    setIsSubmitting(false);
    setCreatedReviewDetails(null);
    setSelectedTrip(null);
    setTrips([]);
    setTripsPage(0);
    setHasExaustedTrips(false);
  }

  function handleRatingClick(rating) {
    if (createdReviewDetails) return;
    setRating(rating);
  }

  function handleSearchTrips() {
    if (isFetchingTrips || hasExaustedTrips) return;

    setIsFetchingTrips(true);
    function handleSuccessFn(res) {
      if (res?.data?.detail?.status === "failed") {
        toast(res?.data?.detail?.message, {
          className: "Toastify__toast__background-danger",
          bodyClassName: "toast__body",
          autoClose: 3000,
        });
        return;
      }

      setTripsPage((prev) => prev + 1);
      const parsedTrips = res?.data?.map((trip) => parseTripObject(trip));
      setTrips((prev) => [...prev, ...parsedTrips]);
      if (!parsedTrips.length) setHasExaustedTrips(true);
    }

    const params = {
      page: tripsPage,
      host_id: hostDetails?.id,
    };
    handlePostApiCall({
      url: `trip/past-trips`,
      params,
      handleSuccessFn,
    }).finally(() => setIsFetchingTrips(false));
  }

  function handleSelectedTripChange(e) {
    setSelectedTrip(trips?.find((trip) => trip?.id === e.target.value) || null);
  }

  function handleReviewChange(e) {
    setReview(e.target.value);
  }

  function handleCustomerNameChange(e) {
    setCustomerName(e.target.value);
  }

  function handleUploadClick() {
    inputRef.current?.click();
  }

  function handleMediaInputReset() {
    inputRef.current.value = "";
  }

  function handleMediaChange(e) {
    if (
      !e.target.files?.length ||
      isMediaUploading ||
      mediaIdsBeingRemoved.length ||
      isSubmitting ||
      medias.length >= 8
    ) {
      return;
    }

    setIsMediaUploading(true);
    const file = e.target.files[0];
    const params = {
      entity_type: reviewForEntityType,
      entity_id: entityId,
      media: file,
    };
    function handleSuccessFn(res) {
      if (res?.data?.detail?.status === "failed") {
        toast(res?.data?.detail?.message, {
          className: "Toastify__toast__background-danger",
          bodyClassName: "toast__body",
          autoClose: 3000,
        });
        return;
      }

      setMedias((prev) => [...prev, res?.data]);
    }

    handlePostApiCall({
      headers: { "Content-Type": "multipart/form-data" },
      url: "review/upload-media",
      params,
      handleSuccessFn,
    }).finally(() => {
      setIsMediaUploading(false);
      handleMediaInputReset();
    });
  }

  const handleMediaRemoval = (mediaId) => () => {
    if (
      isMediaUploading ||
      isSubmitting ||
      mediaIdsBeingRemoved.includes(mediaId)
    ) {
      return;
    }

    setMediasIdsBeingRemoved((prev) => [...prev, mediaId]);
    function handleSuccessFn(res) {
      if (res?.data?.detail?.status === "failed") {
        toast(res?.data?.detail?.message, {
          className: "Toastify__toast__background-danger",
          bodyClassName: "toast__body",
          autoClose: 3000,
        });
        return;
      }

      setMedias((prev) => prev.filter((media) => media?.id !== mediaId));
    }

    const params = {
      entity_type: reviewForEntityType,
      entity_id: entityId,
      media_id: mediaId,
    };
    handlePostApiCall({
      url: "review/remove-media",
      params,
      handleSuccessFn,
    }).finally(() => {
      setMediasIdsBeingRemoved((prev) => prev.filter((id) => id !== mediaId));
      handleMediaInputReset();
    });
  };

  function handleSubmit() {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    const params = {
      entity_type: reviewForEntityType,
      entity_id: entityId,
      rating,
      review,
    };
    if (pageType === "dashboard") params.customer_name = customerName;
    function handleSuccessFn(res) {
      if (res?.data?.detail?.status === "failed") {
        toast(res?.data?.detail?.message, {
          className: "Toastify__toast__background-danger",
          bodyClassName: "toast__body",
          autoClose: 3000,
        });
        return;
      }

      setCreatedReviewDetails(res?.data);
      handleCreatedReviewFn(parseReviewObj(res?.data));
    }

    handlePostApiCall({
      url: "review/create",
      params,
      handleSuccessFn,
    }).finally(() => setIsSubmitting(false));
  }

  if (isDisabledForHost) {
    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          className: styles.reviewDialog,
        }}
        TransitionProps={{
          onExited: handleDataReset,
        }}
      >
        <Stack className={styles.reviewDialog__layoutCtn}>
          <Box className={styles.reviewDialog__header}>
            <Typography className={styles.reviewDialog__header__title}>
              {createdReviewDetails ? "Review Submitted" : "Write a review"}
            </Typography>
            <IconButton
              className={styles.reviewDialog__header__closeBtn}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography className={styles.reviewDialog__hostRedirect__text}>
              Head over to your{" "}
              <ALink
                href="/create-host#reviews"
                aProps={{
                  className: styles.reviewDialog__hostRedirect__link,
                }}
              >
                dashboard
              </ALink>{" "}
              to create a review.
            </Typography>
          </Box>
        </Stack>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        className: styles.reviewDialog,
      }}
      TransitionProps={{
        onExited: handleDataReset,
      }}
    >
      <Stack className={styles.reviewDialog__layoutCtn}>
        <Box className={styles.reviewDialog__header}>
          <Typography className={styles.reviewDialog__header__title}>
            {createdReviewDetails ? "Review Submitted" : "Write a review"}
          </Typography>
          <IconButton
            className={styles.reviewDialog__header__closeBtn}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {!createdReviewDetails && (
          <Stack className={styles.reviewDialog__instructionsCtn}>
            <Typography className={styles.reviewDialog__instructionsCtn__title}>
              <InfoIcon /> Review guide: Pick your way of expression
            </Typography>
            <ul className={styles.reviewDialog__instructionsCtn__listCtn}>
              {reviewSubmissionOptions.map((option) => (
                <li style={{ lineHeight: "1em" }}>
                  <Typography
                    className={styles.reviewDialog__instructionsCtn__list__text}
                  >
                    <span>{option.title}:</span> {option.description}
                  </Typography>
                </li>
              ))}
            </ul>
          </Stack>
        )}
        {pageType === "dashboard" && !createdReviewDetails && (
          <Stack className={styles.reviewDialog__customerNameCtn}>
            <Typography className={styles.reviewDialog__customerNameCtn__title}>
              Customer Name
            </Typography>

            <TextField
              inputProps={{
                className: styles.reviewDialog__customerNameCtn__input,
              }}
              value={customerName}
              onChange={handleCustomerNameChange}
              placeholder="Enter customer name"
            />
          </Stack>
        )}
        {!createdReviewDetails && (
          <Stack className={styles.reviewDialog__selectTripCtn}>
            <Typography className={styles.reviewDialog__selectTripCtn__title}>
              Select the trip
            </Typography>
            <FormControl size="small">
              <InputLabel
                id="select-review-trip-label"
                className={styles.reviewDialog__selectTripCtn__text}
              >
                Select recent trip
              </InputLabel>
              <Select
                className={styles.reviewDialog__selectTripCtn__text}
                labelId="select-review-trip-label"
                label="Select recent trip"
                size="small"
                IconComponent={(props) => <ExpandMoreIcon {...props} />}
                value={selectedTrip?.id || ""}
                onChange={handleSelectedTripChange}
                MenuProps={{
                  sx: {
                    maxHeight: 300,
                  },
                }}
              >
                {trips?.map((trip) => (
                  <MenuItem
                    key={trip?.id}
                    className={styles.reviewDialog__selectTripCtn__text}
                    value={trip?.id}
                    dense
                  >
                    {trip?.startingLocation} to {trip?.highlightLocation}{" "}
                    {getDateInSlashSeparatorFormat(trip?.startDate)}
                  </MenuItem>
                ))}
                {isFetchingTrips && (
                  <Stack alignItems="center">
                    <CircularProgress size={20} />
                  </Stack>
                )}
                {!hasExaustedTrips && (
                  <Box sx={{ width: 0, height: 0 }} ref={loadMoreTripsRef} />
                )}
              </Select>
            </FormControl>
          </Stack>
        )}
        <Stack className={styles.reviewDialog__ratingsCtn}>
          {!createdReviewDetails && (
            <Typography className={styles.reviewDialog__ratingsCtn__title}>
              Add Ratings
            </Typography>
          )}
          <Box
            className={[
              styles.reviewDialog__ratingsCtn__starCtn,
              createdReviewDetails &&
                styles.reviewDialog__ratingsCtn__starCtn__success,
            ].join(" ")}
          >
            <StarRating
              rating={
                createdReviewDetails ? createdReviewDetails?.rating : rating
              }
              starSize={starSize}
              handleRatingClick={handleRatingClick}
              isNonEditable={createdReviewDetails}
            />
          </Box>
        </Stack>
        <Stack className={styles.reviewDialog__reviewCtn}>
          {createdReviewDetails ? (
            createdReviewDetails?.review && (
              <Typography className={styles.reviewDialog__reviewCtn__text}>
                {createdReviewDetails?.review}
              </Typography>
            )
          ) : (
            <>
              <Typography className={styles.reviewDialog__reviewCtn__title}>
                Write {pageType === "dashboard" ? "their" : "your"} review
                (optional)
              </Typography>
              <TextareaAutosize
                className={styles.reviewDialog__reviewCtn__input}
                minRows={6}
                maxRows={6}
                value={review}
                onChange={handleReviewChange}
                placeholder="What would you like to write about this trip?"
              />
            </>
          )}
        </Stack>
        <Stack className={styles.reviewDialog__mediaCtn}>
          {!createdReviewDetails && (
            <Typography className={styles.reviewDialog__mediaCtn__title}>
              Add Photos or Videos (optional)
            </Typography>
          )}
          <MediaSlider
            smaller
            medias={mediasToUse}
            enableRemoval={!createdReviewDetails}
            mediaIdsBeingRemoved={mediaIdsBeingRemoved}
            handleMediaRemoval={handleMediaRemoval}
            sliderSettingsProp={
              !isMobile && {
                slidesToShow: 3.75,
              }
            }
          >
            {!createdReviewDetails && (
              <Button
                className={styles.reviewDialog__mediaCtn__uploadBtn}
                onClick={handleUploadClick}
                disabled={isMediaUploading}
              >
                {isMediaUploading ? (
                  <CircularProgress size={32} />
                ) : (
                  <>
                    <Image src={UploadIcon} />
                    <Typography
                      className={styles.reviewDialog__mediaCtn__uploadBtn__text}
                    >
                      Upload
                    </Typography>
                  </>
                )}
                <input
                  type="file"
                  accept="image/*,video/*"
                  onChange={handleMediaChange}
                  style={{
                    display: "none",
                    position: "absolute",
                  }}
                  ref={inputRef}
                />
              </Button>
            )}
          </MediaSlider>
        </Stack>
        {!createdReviewDetails && (
          <Box className={styles.reviewDialog__submitCtn}>
            <Button
              variant="contained"
              className={styles.reviewDialog__submitBtn}
              onClick={handleSubmit}
              disableElevation
            >
              {isSubmitting ? (
                <CircularProgress size={28} color="secondary" />
              ) : (
                "Submit Review"
              )}
            </Button>
          </Box>
        )}
      </Stack>
    </Dialog>
  );
}

export default ReviewDialog;
