import React from "react";
import { Box } from "@mui/material";
import Image from "next/image";
import GrayStarIcon from "../../public/images/icons/star_gray.svg";
import GreenStarIcon from "../../public/images/icons/star_green.svg";
import styles from "./index.module.css";

function StarRating({
  rating = 0,
  starSize = 24,
  handleRatingClick = () => {},
  isNonEditable = false,
}) {
  return (
    <Box className={styles.review__starsCtn}>
      {Array.from({ length: 5 }).map((_, idx) =>
        idx < rating ? (
          <Image
            key={idx}
            className={!isNonEditable && styles.review__star__pointer}
            width={starSize}
            height={starSize}
            src={GreenStarIcon}
            alt="star"
            onClick={() => handleRatingClick(idx + 1)}
          />
        ) : (
          <Image
            key={idx}
            className={!isNonEditable && styles.review__star__pointer}
            width={starSize}
            height={starSize}
            src={GrayStarIcon}
            alt="star"
            onClick={() => handleRatingClick(idx + 1)}
          />
        )
      )}
    </Box>
  );
}

export default StarRating;
