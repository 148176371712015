import React, { useState } from "react";
import { Box, IconButton, CircularProgress } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import Image from "next/image";
import Slider from "react-slick";
import MediaViewer, { SlickVideo } from "../ui/media-viewer/media.viewer";
import PlayIcon from "../../public/images/icons/play_icon.svg";
import styles from "./index.module.css";

const sliderSettings = {
  dots: false,
  speed: 500,
  infinite: false,
  focusOnSelect: false,
  arrows: false,
  slidesToShow: 3.4,
  slidesToScroll: 2,
};

function MediaSlider({
  medias = [],
  smaller = false,
  enableRemoval = false,
  mediaIdsBeingRemoved = [],
  handleMediaRemoval = () => {},
  sliderSettingsProp = {},
  children,
}) {
  const [isDragging, setIsDragging] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeMediaIndex, setActiveMediaIndex] = useState(0);

  function handleBeforeChange() {
    setIsDragging(true);
  }

  function handleAfterChange() {
    setIsDragging(false);
  }

  const handleMediaClick =
    (mediaIndex = 0) =>
    () => {
      if (isDragging) return;
      setActiveMediaIndex(mediaIndex);
      setIsFullScreen(true);
    };

  function handleFullScreenClose() {
    setIsFullScreen(false);
    setActiveMediaIndex(0);
  }

  return (
    <>
      <Slider
        {...sliderSettings}
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
        {...sliderSettingsProp}
      >
        {children}
        {medias.map((media, idx) => (
          <Box
            key={media?.id ?? idx}
            className={[
              styles.review__mediaCtn__itemCtn,
              smaller && styles.review__mediaCtn__itemCtn__small,
            ].join(" ")}
            onClick={handleMediaClick(idx)}
          >
            <Image
              layout="fill"
              src={
                (media?.media_type === "image" && media?.cdn_url) ||
                (media?.media_type === "video" && media?.thumbnail_cdn_url)
              }
            />
            {enableRemoval && (
              <IconButton
                className={styles.review__mediaCtn__itemCtn__removeBtn}
                onClick={handleMediaRemoval(media?.id)}
              >
                {mediaIdsBeingRemoved.includes(media?.id) ? (
                  <CircularProgress size={14} color="secondary" />
                ) : (
                  <CloseIcon
                    className={
                      styles.review__mediaCtn__itemCtn__removeBtn__icon
                    }
                  />
                )}
              </IconButton>
            )}
            {media?.media_type === "video" && (
              <Box className={styles.review__mediaCtn__itemCtn__playIcon}>
                <Image width={36} height={36} src={PlayIcon} />
              </Box>
            )}
          </Box>
        ))}
      </Slider>
      <MediaViewer
        medias={medias}
        open={isFullScreen}
        onClose={handleFullScreenClose}
        activeIdx={activeMediaIndex}
        setActiveItemIdx={setActiveMediaIndex}
        renderDisplayedItemFn={(media, idx) => {
          if (media?.media_type === "video") {
            return (
              <video
                key={media?.id}
                controls
                autoPlay
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <source src={media?.cdn_url} />
              </video>
            );
          }

          return <img src={media?.cdn_url} />;
        }}
        renderMobileItem={(media, idx) => {
          if (media?.media_type === "video") {
            return (
              <SlickVideo
                key={media?.id}
                src={media?.cdn_url}
                play={idx === activeMediaIndex}
              />
            );
          }

          return <img src={media?.cdn_url} alt={media?.caption} />;
        }}
      />
    </>
  );
}

export default MediaSlider;
